<template>
  <VFooter
    class="footer body-2"
    app
    inset
    padless
  >
    <VContainer
      fluid
      class="box"
    >
      <VRow align="center">
        <VCol
          cols="12"
          sm="6"
        >
          <p>
            <a
              v-if="vendor.profile && vendor.profile.website"
              :href="vendor.profile.website"
              target="_blank"
              rel="nofollow"
            >{{ vendor.title }}</a>

            <template v-else>
              {{ vendor.title }}
            </template>
          </p>

          <p
            v-if="phone"
            class="text-no-wrap"
          >
            {{ $t('phones.one') }}: <a :href="`tel:${rawPhone}`">{{ phone }}</a>
          </p>

          <p v-if="vendor.branding.show_privacy_policy_link">
            <RouterLink
              :to="{ name: 'privacy-policy' }"
              target="_blank"
            >
              {{ $t('personal_data_processing_policy.one') }}
            </RouterLink>
          </p>
        </VCol>

        <VSpacer />

        <VCol
          cols="12"
          sm="6"
          class="text-sm-right"
        >
          <VBtn
            v-for="socialItem in socialList"
            :key="socialItem.title"
            :title="socialItem.title"
            :href="`${socialItem.url}`"
            target="_blank"
            icon
            large
          >
            <VIcon>
              {{ socialItem.icon }}
            </VIcon>
          </VBtn>
        </VCol>
      </VRow>
    </VContainer>
  </VFooter>
</template>

<script>
import { mapGetters } from 'vuex'

import * as getters from '@/store/getters/types'

const socialIcons = [
  {
    names: /vk\./,
    icon: '$uchi_vk',
    title: 'ВКонтакте'
  },
  {
    names: /youtube\.|youtu\.be/,
    icon: '$uchi_youtube',
    title: 'YouTube'
  },
  {
    names: /telegram\.|t\.me/,
    icon: '$uchi_telegram',
    title: 'Telegram'
  },
  {
    names: /viber\./,
    icon: '$uchi_viber',
    title: 'Viber'
  },
  {
    names: /whatsapp\.|wa\.me/,
    icon: '$uchi_whatsapp',
    title: 'WhatsApp'
  }
]

export default {
  name: 'UFooter',

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR
    }),

    phone () {
      return this.vendor?.profile?.phone || this.vendor?.phone || ''
    },

    rawPhone () {
      return this.phone.replace(/[() -]/g, '')
    },

    socialList () {
      const onlinePresence = this.vendor?.branding?.online_presence || []

      return onlinePresence.reduce((acc, link) => {
        const social = socialIcons.find(socialIcon => socialIcon.names.test(link))
        if (social) {
          return [...acc, {
            url: link,
            icon: social.icon,
            title: social.title
          }]
        }

        return acc
      }, [])
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .footer {
    padding-top: 1rem;
    position: absolute;
    text-align: center;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      text-align: left;
    }

    a {
      color: inherit;
    }

    p {
      margin: 0;
    }
  }
}
</style>
