<template>
  <article class="guest-page">
    <div class="guest-page__body">
      <VAppBar
        class="flex-grow-0"
        color="white"
        elevation="0"
        dense
      >
        <VSpacer />
        <LocaleSwitch
          v-if="!vendor.branding?.hide_language_selector"
        />
      </VAppBar>
      <div class="guest-page__content">
        <slot />
      </div>
    </div>

    <div
      class="guest-page__aside"
      :style="styles"
    >
      <VOverlay
        absolute
        z-index="0"
        color="rgba(0, 0, 0, 0.5)"
      />
      <div
        class="vendor-info"
        :style="textStyles"
      >
        <div class="vendor-info__slogan">
          {{ slogan }}
        </div>
        <footer class="vendor-info__footer">
          <div
            v-if="phone"
            class="text-no-wrap"
          >
            <a :href="`tel:${rawPhone}`">{{ phone }}</a>
          </div>
          <div>
            <a
              v-if="vendor.profile && vendor.profile.website"
              :href="vendor.profile.website"
              target="_blank"
              rel="noopener noreferrer"
            >{{ vendor.title }}</a>
            <template v-else>
              {{ vendor.title }}
            </template>
          </div>
        </footer>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'

import * as getters from '@/store/getters/types'
import LocaleSwitch from '@/components/LocaleSwitch.vue'

import bgImage from '@/assets/images/bg-account.jpg'

const defaultSlogan = 'Обучайте своих сотрудников дистанционно — мы вам поможем.'

export default {
  name: 'GuestLayout',
  components: {
    LocaleSwitch
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR
    }),

    phone () {
      return this.vendor?.profile?.phone || this.vendor?.phone || ''
    },

    rawPhone () {
      return this.phone.replace(/[() -]/g, '')
    },

    slogan () {
      if (!this.vendor?.branding?.hide_slogan) {
        return this.vendor?.branding?.slogan || this.vendor?.branding?.default_slogan || defaultSlogan
      }
      return ''
    },

    textStyles () {
      const isCustom = this.vendor?.branding?.theme === 'custom'
      const color = this.vendor?.branding?.custom_colors?.guest_page?.fg
      return {
        color: isCustom ? color : 'white'
      }
    },

    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    },

    styles () {
      const background = this.vendor?.branding?.background
      const defaultImage = this.isDevelopment ? bgImage : '/assets/images/bg-account.jpg'
      const backgroundPath = background ? `url(/${background})` : `url(${defaultImage})`

      return { backgroundImage: backgroundPath }
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .guest-page {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    min-height: 100vh;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-flow: row nowrap;
    }

    .logo {
      width: 12rem;
    }
  }

  .guest-page__body {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
    background-color: white;
    max-height: 100vh;
    overflow-y: scroll;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      align-self: flex-start;
      min-height: 100vh;
      flex: 0 0 480px;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      flex: 0 0 600px;
    }
  }

  .guest-page__aside {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    text-align: center;
    background-size: cover;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex: 1 1 auto;
      text-align: left;
    }
  }

  .guest-page__content {
    text-align: center;
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    position: relative;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin: 2rem auto;
    }
  }

  .vendor-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    position: relative;
    color: white;
    padding: 2rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 1.5rem;
      min-height: 100vh;
      max-width: 42rem;
      padding: 2rem;
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      padding: 2rem 6rem;
    }

    a {
      color: inherit;
    }
  }

  .vendor-info__slogan {
    display: none;
    font-size: 1.625rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: block;
    }
  }

  .vendor-info__footer {
    font-size: 0.875rem;

    a {
      text-decoration: underline;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: 2rem;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
</style>
