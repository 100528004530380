<template>
  <Fragment>
    <VNavigationDrawer
      :class="['main-menu', {'custom-theme': isCustomTheme}]"
      app
      clipped
      floating
      width="100"
    >
      <VBtn
        v-if="account.profile.is_default_listener"
        @click="dialog=true"
      >
        <VIcon>$uchi_orders</VIcon>
        {{ $t('order_management.one') }}
      </VBtn>
      <template v-for="menuItem in menuItems">
        <VBtn
          :key="menuItem.name"
          :to="menuItem.to"
          :href="menuItem.href"
        >
          <VBadge
            :value="menuItem.counter"
            :content="menuItem.counter"
            overlap
          >
            <VIcon>{{ menuItem.icon }}</VIcon>
          </VBadge>
          {{ $t(menuItem.title) }}
        </VBtn>
      </template>
    </VNavigationDrawer>

    <VAppBar
      app
      class="app-bar"
      clipped-left
      elevation="1"
    >
      <RouterLink
        :to="{ name: 'sessions' }"
        class="app-bar__logo"
        :title="vendor.title"
      >
        <VImg
          :src="(vendor.branding && vendor.branding.logo) ? `/${ vendor.branding.logo }` : logotype"
          :alt="vendor.title"
          contain
        />
      </RouterLink>

      <VToolbarTitle class="text-md-center flex-grow-1">
        <RouterLink :to="{ name: 'sessions' }">
          {{ vendor.title }}
        </RouterLink>
      </VToolbarTitle>

      <VBtn
        v-if="!vendor.branding?.hide_a11y_controls"
        class="bvi-open"
        icon
        :title="$t('$app.version_visually_impaired')"
        @click="bviRefreshPage"
      >
        <VIcon>$uchi_bvi</VIcon>
      </VBtn>

      <VBtn
        v-if="isDevelopment"
        icon
        title="Settings"
        @click="settings = true"
      >
        <VIcon>settings</VIcon>
      </VBtn>

      <ContextHelp />

      <LocaleSwitch
        v-if="!vendor.branding?.hide_language_selector"
      />

      <VMenu
        offset-y
        open-on-hover
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            v-bind="attrs"
            text
            :elevation="0"
            v-on="on"
          >
            <VAvatar
              v-if="account.profile.photo"
              size="32"
            >
              <VImg
                :src="`/${account.profile.photo}`"
                :alt="account.title"
              />
            </VAvatar>
            <VIcon v-else>
              person
            </VIcon>
            <span class="account-title">{{ accountTitle }}</span>
            <VIcon>expand_more</VIcon>
          </VBtn>
        </template>

        <VList dense>
          <template v-if="vendor.branding && !vendor.branding.old_listener_cabinet_hidden">
            <VListItem href="/training/sessions">
              <VListItemContent>
                <VListItemTitle>
                  Старый личный кабинет
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
            <VDivider />
          </template>
          <VListItem :to="`/users/${account.id}`">
            <VListItemContent>
              <VListItemTitle>{{ $t('profiles.one') }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem
            v-if="account.profile.is_default_listener"
            link
          >
            <VListItemContent>
              <VListItemTitle @click="dialog=true">
                {{ $t('order_management.one') }}
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VListItem
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            :to="menuItem.to"
            :href="menuItem.href"
          >
            <VListItemContent>
              <VListItemTitle>{{ $t(menuItem.title) }}</VListItemTitle>
            </VListItemContent>
            <VListItemIcon>
              <VBadge
                :value="menuItem.counter"
                :content="menuItem.counter"
                inline
              />
            </VListItemIcon>
          </VListItem>
          <VDivider />
          <VListItem @click.prevent="logoutAccount">
            <VListItemContent>
              <VListItemTitle>{{ $t('anonymous_sign_out.one') }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
        </VList>
      </VMenu>
    </VAppBar>

    <VNavigationDrawer
      v-if="isDevelopment"
      v-model="settings"
      fixed
      temporary
      hide-overlay
      right
    >
      <VToolbar>
        <VToolbarTitle>Настройки</VToolbarTitle>
        <VSpacer />
        <VBtn
          title="Свернуть"
          icon
          @click="settings = false"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VToolbar>
      <VDivider />
      <VContainer>
        <VSwitch
          v-model="$vuetify.theme.dark"
          inset
          label="Тёмная тема"
        />
        <VSelect
          v-model="theme"
          :items="themes"
          item-text="localizedTitle"
          item-value="title"
          label="Тема оформления"
          dense
          outlined
          @change="setTheme"
        />
      </VContainer>
    </VNavigationDrawer>
    <VDialog
      v-model="dialog"
      max-width="400"
    >
      <VCard>
        <VForm
          method="POST"
          :action="'/users/'+ account.parent_id + '/login'"
          autocomplete="off"
        >
          <VCardTitle>
            {{ $t('$app.go_management') }}
          </VCardTitle>
          <VDivider />
          <VCardActions>
            <VSpacer />
            <VBtn
              color="primary"
              text
              @click="dialog = false"
            >
              {{ $t('no.one') }}
            </VBtn>
            <VBtn
              color="error"
              type="submit"
            >
              {{ $t('yes.one') }}
            </VBtn>
          </VCardActions>
        </VForm>
      </VCard>
    </VDialog>

    <VMain :data-with-banner="withBanner">
      <slot />
    </VMain>
    <UFooter />
  </Fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Fragment } from 'vue-fragment'

import logotype from '@/assets/images/uchi.svg'
import { initSpeechPlayer, bviRefreshPage } from '@/utils/common'
import { THEMES } from '@/utils/themes'
import { newMessagesCounter } from '@/utils/messagesCounter'
import UFooter from '@components/UFooter.vue'
import ContextHelp from '@/components/ContextHelp.vue'
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

import bvi from 'bvi'

class MenuItem {
  constructor ({
    name = '',
    title = '',
    icon = '',
    href = '',
    to = '',
    disabled = false,
    counter = 0
  }) {
    this.name = name
    this.title = title
    this.icon = icon
    this.href = href
    this.to = to
    this.disabled = disabled
    this.counter = counter
  }
}

export default {
  name: 'ULayoutMain',
  components: {
    Fragment,
    ContextHelp,
    LocaleSwitch,
    UFooter
  },

  data () {
    return {
      logotype,
      theme: '',
      settings: false,
      dialog: false,
      themes: Object.values(THEMES),
      withBanner: false,
      componentsWithBanner: [
        'sessions',
        'messenger',
        'marks',
        'history',
        'meetings',
        'groups',
      ]
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR,
      limits: getters.VENDOR_LIMITS,
      countNewMessages: getters.COUNT_NEW_MESSAGES,
      countNewSessions: getters.COUNT_NEW_SESSIONS,
      countNewMeetings: getters.COUNT_NEW_MEETINGS,
      countNewSurveys: getters.COUNT_NEW_SURVEYS
    }),

    menuItems () {
      const initialMenuItems = [
        new MenuItem({
          name: 'library',
          title: '$app.menuItem.library',
          icon: '$uchi_lib',
          to: '/library',
          disabled: !this.limits.infobase_available
        }),

        new MenuItem({
          name: 'programs',
          title: 'sessions.other',
          icon: '$uchi_programs',
          to: '/sessions',
          counter: this.countNewSessions
        }),

        new MenuItem({
          name: 'surveys',
          title: 'surveys.other',
          icon: '$uchi_surveys',
          to: '/surveys',
          disabled: !this.limits.surveys_available,
          counter: this.countNewSurveys
        }),

        new MenuItem({
          name: 'messages',
          title: 'messages.other',
          icon: '$uchi_messages',
          to: '/messages',
          disabled: this.limits.messages_disabled,
          counter: this.countNewMessages
        }),

        new MenuItem({
          name: 'marks',
          title: 'marks.other',
          icon: '$uchi_marks',
          to: '/marks'
        }),

        new MenuItem({
          name: 'students_record_book',
          title: 'students_record_book.one',
          icon: '$uchi_history',
          to: '/history'
        }),

        new MenuItem({
          name: 'meetings',
          title: 'meetings.two',
          icon: '$uchi_meetings',
          to: '/meetings',
          disabled: !this.limits.meetings_available,
          counter: this.countNewMeetings
        }),

        new MenuItem({
          name: 'groups',
          title: '$app.menuItem.groups',
          icon: '$uchi_groups',
          to: '/groups',
          disabled: !this.limits.groups_writs_available
        })
      ]

      return initialMenuItems.filter(item => !item.disabled)
    },

    accountTitle () {
      return this.account?.title
        .split(' ')
        .map((str, i) => i !== 0 ? `${str[0]}.` : str)
        .join(' ')
    },

    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    },

    isCustomTheme () {
      return this.vendor?.branding?.theme === 'custom'
    }
  },

  watch: {
    $route: {
      handler: function (to) {
        this.$nextTick(() => {
          this.checkBannerExistence(to.name)
        })
      }
    }
  },

  created () {
    this.register()
    this.checkBannerExistence(this.$route.name)

    initSpeechPlayer().observe(
      document.body,
      { childList: true, subtree: true }
    )
  },

  mounted () {
    bviRefreshPage()
    // eslint-disable-next-line no-new

    window.bvi = new bvi.Bvi({
      theme: 'white',
      images: 'grayscale',
      builtElements: true
    })
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      logoutAccount: actions.LOGOUT,
      getSessions: actions.GET_SESSIONS,
      getMeetings: actions.GET_MEETINGS,
      getSurveys: actions.GET_SURVEYS,
      getVendorLimits: actions.GET_VENDOR_LIMITS
    }),

    bviRefreshPage,
    setTheme (theme) {
      const _theme = THEMES[theme]
      if (!_theme) {
        return
      }

      this.$vuetify.theme.themes.light.background = _theme.background
      this.$vuetify.theme.themes.dark.background = _theme.background

      this.$vuetify.theme.themes.light.text = _theme.text
      this.$vuetify.theme.themes.dark.text = _theme.text
    },

    async register () {
      const branding = this.vendor?.branding

      if (branding && branding.theme) {
        if (branding.theme === 'custom') {
          const sidebarBg = branding.custom_colors?.sidebar?.bg
          const selectedSidebarBg = branding.custom_colors?.sidebar_selected?.bg
          document.body.style.setProperty('--nav-background-up-color', selectedSidebarBg)
          document.body.style.setProperty('--nav-background-down-color', sidebarBg)
          this.$vuetify.theme.themes.light.text = 'white'
          this.$vuetify.theme.themes.dark.text = 'white'
        } else {
          this.setTheme(branding.theme)
        }
      }

      await this.getVendorLimits(this.vendor.id)
      if (!this.limits?.messages_disabled) {
        newMessagesCounter.start()
      }

      if (this.$route.name !== 'sessions') {
        /* Незаметно загрузить список сессий, чтобы показать счетчик */
        this.getSessions(true)
      }

      if (this.$route.name !== 'meetings' && this.limits.meetings_available) {
        /* Незаметно загрузить список мероприятий, чтобы показать счетчик */
        this.getMeetings(true)
      }

      if (this.$route.name !== 'surveys' && this.limits.surveys_available) {
        /* Незаметно загрузить список опросов, чтобы показать счетчик */
        this.getSurveys(true)
      }
    },

    unregister () {
      if (!this.limits?.messages_disabled) {
        newMessagesCounter.stop()
      }
    },

    checkBannerExistence (name) {
      if (this.componentsWithBanner.includes(name)) {
        this.withBanner = true
      } else {
        this.withBanner = false
      }
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .main-menu {
    background-image: linear-gradient(to bottom, var(--v-background-lighten2), var(--v-background-base));

    .v-btn.v-size--default {
      height: auto;
      color: var(--v-text-base);
      background-color: transparent !important;
      display: block;
      width: 100%;
      min-width: 100%;
      box-shadow: none;
      white-space: normal;
      text-align: center;
      padding: ($spacer * 4) $spacer;
      text-transform: none;
      letter-spacing: normal;
    }

    .v-btn__content {
      flex-direction: column;
    }

    .v-icon,
    .v-icon__svg {
      font-size: 32px;
      width: 32px;
      height: 32px;
    }
  }

  .main-menu.custom-theme {
    background-image: linear-gradient(to bottom, var(--nav-background-up-color), var(--nav-background-down-color));
  }

  .theme--light {
    &.app-bar.v-toolbar.v-sheet {
      background-color: white;
    }
  }

  .app-bar__logo {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: var(--app-navigation-width);
      height: var(--app-bar-height);
      margin-left: -1rem;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    > * {
      height: 100%;
    }
  }

  .v-toolbar__title {
    font-size: map-deep-get($headings, "h4", "size");

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .account-title {
    display: none;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: inline;
      text-transform: none;
      letter-spacing: normal;
      margin-right: $spacer;
      margin-left: $spacer;
    }
  }
}
</style>
